
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

import { Icon } from "@iconify/vue";
import downloadIcon from "@iconify-icons/tabler/download";
import emailIcon from "@iconify-icons/tabler/mail";
import linkedInIcon from "@iconify-icons/tabler/brand-linkedin";
import siteIcon from "@iconify-icons/tabler/world";
import phoneIcon from "@iconify-icons/tabler/phone";
import githubIcon from "@iconify-icons/tabler/brand-github";
import twitterIcon from "@iconify-icons/tabler/brand-twitter";

export default defineComponent({
  name: "Resume",
  components: {
    Icon,
  },
  setup() {
    // window.addEventListener("resize", () => {
    //   const e = document.querySelector(".resume");
    //   if (e) console.log(e.clientWidth / e.clientHeight);
    // });

    const resume = ref<HTMLElement>();

    const scaleResumeContent = () => {
      if (!resume.value) return;

      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.width = "210mm";
      div.style.pointerEvents = "none";
      document.body.append(div);

      const maxWidth = div.getBoundingClientRect().width;
      document.body.removeChild(div);

      const rect = resume.value.getBoundingClientRect();
      const scale = rect.width / maxWidth;

      const content = document.querySelector(
        ".resume .content"
      ) as HTMLDivElement;
      if (content) content.style.transform = `scale(${scale})`;
    };

    onMounted(() => {
      scaleResumeContent();
      window.addEventListener("resize", scaleResumeContent);
    });
    onUnmounted(() => window.removeEventListener("resize", scaleResumeContent));

    return {
      resume,

      icons: {
        download: downloadIcon,
        email: emailIcon,
        linkedIn: linkedInIcon,
        site: siteIcon,
        phone: phoneIcon,
        github: githubIcon,
        twitter: twitterIcon,
      },
    };
  },
});
